import React, { Col, Container, Form, Row, Button } from "react-bootstrap";
const GetStarted = () => {
    return (
        <>
            <section className="get-started-section">
                <Container fluid className="h-100">

                    <Row >
                        <Col md={6}>
                            <div className="login-top-logo">
                            <a href="https://insureo.io/" target="_blank"><img src={require("../assets/images/logo.png")} /></a>
                            </div>
                            <div className="get-content">
                                <h2 className="mt-2">Let's Get Started!</h2>
                                <Form.Group className="mt-4 website-title" controlId="formBasicEmail">
                                    <Form.Label>Title of your Website</Form.Label>
                                    <Form.Control type="email" placeholder="Enter Title of your Website" />
                                </Form.Group>
                                <Form.Group className="mt-4 website-title" controlId="formBasicEmail">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="email" placeholder="Enter First Name" />
                                </Form.Group>
                                <Form.Group className="mt-4 website-title" controlId="formBasicEmail">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="email" placeholder="Enter Last Name" />
                                </Form.Group>
                                <Form.Group className="mt-4 website-title" controlId="formBasicEmail">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="email" placeholder="Enter Phone Number" />
                                </Form.Group>
                           
                                {/* <Form.Check className="active" type="radio" aria-label="radio 1" label="For my self, my business or my friend" />
                                <Form.Check type="radio" aria-label="radio 1" label="For a client, as a freelancer or an agency " />
                                <Form.Check type="radio" aria-label="radio 1" label="For a client, as a freelancer or an agency " /> */}
                                <div className="insurance-selection get-started-selection">
                                        <h6>Select Insurance Type <img src={require("../assets/images/icons8-select.gif")} /></h6>
                                        <Form>
                                            {['checkbox'].map((type) => (
                                                <div key={`default-${type}`} className="mb-3">
                                                    <Form.Check // prettier-ignore
                                                        type={type}
                                                        id={`default-${type}`}
                                                        label={`Travel Insurance`}
                                                    />

                                                    <Form.Check
                                                        type={type}
                                                        label={`Life Insurance`}
                                                        id={`default-${type}`}
                                                    />
                                                    <Form.Check
                                                        type={type}
                                                        label={`General Insurance`}
                                                        id={`default-${type}`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                    </div>
                                <Button className="get-started-btn-main"><a href="/pick-theme">Continue</a></Button>

                            </div>
                        </Col>
                        <Col md={6} className="bg-gradient">
                            <div className="screen-img-box">
                                {/* <img src={require("../assets/images/screen-bg.png")} />
                                <div className="screen-img-box-content">
                                    <div className="screen-head-area">
                                        <div className="screen-head-area-left">
                                            <h6>William Joe</h6>
                                            <p><i class="fa-solid fa-envelope"></i> Williamjoe@gmail.com</p>
                                        </div>
                                        <img src={require("../assets/images/client_icon.png")} />
                                    </div>
                                    <div className="screen-img-body">
                                        <Row className="justify-content-center">
                                            <Col md={6} className="mb-4">
                                                <div className="isurance-area">
                                                    <img src={require("../assets/images/visitor.svg").default} />
                                                    <h6>Visitor</h6>
                                                </div>
                                            </Col>
                                            <Col md={6} className="mb-4">
                                                <div className="isurance-area">
                                                    <img src={require("../assets/images/super-visa.svg").default} />
                                                    <h6>Super Visa</h6>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className="justify-content-center">
                                            <Col md={6} className="mb-4">
                                                <div className="isurance-area">
                                                    <img src={require("../assets/images/student.svg").default} />
                                                    <h6>Student</h6>
                                                </div>
                                            </Col>
                                            <Col md={6} className="mb-4">
                                                <div className="isurance-area">
                                                    <img src={require("../assets/images/trip.svg").default} />
                                                    <h6>Trip</h6>
                                                </div>
                                            </Col>

                                        </Row>
                                        <div className="phone-icon-bottom">
                                            <img src={require("../assets/images/call_icon.png")} />
                                           <p>Call me!</p>
                                        </div>
                                    </div>
                                </div> */}
                                <img src={require("../assets/images/preview.png")} />
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
};

export default GetStarted;