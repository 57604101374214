import { useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";

const Setting = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <section className="common-spacing">
                <Container fluid>
                    <div className="policy-management-area">
                        <div className="policy-management-top">
                           
                        </div>
                        <div className="">
                            <div className="settings-module ">
                                <Row className="justify-content-center">
                                    <Col md={7} className="policy-management-content p-4">
                                        <h4 className="mt-0">Settings</h4>
                                        <div className="setting-box-body">
                                            <p>Enable Full access</p>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label="Enable"
                                            />
                                        </div>
                                        <div className="setting-box-body mt-3">
                                            <p>Enable Free Version</p>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label="Enable"
                                            />
                                        </div>
                                        <div className="advertising-section mt-3">
                                            <img src={require("../assets/images/no-promotion.png")} className="no-adds" alt="advertising" />
                                            <div>
                                                <h4>No Adds <span>$122.00 per month</span></h4>
                                                <Button className="Buy-btn">Buy</Button>
                                            </div>
                                        </div>
                                        <div className="setting-box-body mt-3">
                                            <p>Enable Policy Management</p>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label="Enable"
                                            />
                                        </div>
                                        <div className="advertising-section unlimited-section mt-3 d-block pb-3 px-2">
                                            <div className="d-flex justify-content-between py-2">
                                                <h5>Unlimited</h5>
                                                <Button className="Buy-btn">Buy $5.00</Button>
                                            </div>
                                            <div className="unlimited-body">
                                                <p><i className="fa-solid fa-check-double"></i> Manage all client at one level</p>
                                                <p><i className="fa-solid fa-check-double"></i> Auto reminder for renewal</p>
                                                <p><i className="fa-solid fa-check-double"></i> Auto remote pf expiring.</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
};
export default Setting;