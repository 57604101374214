import React, { Col, Container, Form, Row, Button } from "react-bootstrap";
const SignUp = () => {
    return (
        <>
            <section className="signup-area">
                <Container fluid>
                    <div className="header-logo signup-head">
                    <a href="https://insureo.io/" target="_blank"><img src={require("../assets/images/logo.png")} /></a>
                    </div>
                    <Row className="justify-content-center">
                        <Col md={10}>
                            <h2>Sign Up</h2>
                            <p>lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                            <div className="signup-area-content" data-aos="fade-up" data-aos-duration="1000">
                                <Form>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="email" placeholder="Enter Name" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Email Address</Form.Label>
                                                <Form.Control type="email" placeholder="Enter Email" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Corporation Detail</Form.Label>
                                                <Form.Control type="email" placeholder="Enter Corporation Detail" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Phone Number</Form.Label>
                                                <Form.Control type="email" placeholder="Enter Phone Number" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Website</Form.Label>
                                                <Form.Control type="email" placeholder="Enter Website" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control type="email" placeholder="Address" />
                                            </Form.Group>
                                        </Col>
                                        <h6>License Details <i class="fa-solid fa-plus"></i></h6>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>License Number</Form.Label>
                                                <Form.Control type="email" placeholder="Enter License Number" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Expiry Date</Form.Label>
                                                <Form.Control type="email" placeholder="Enter Expiry Date" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label>Country</Form.Label>
                                            <Form.Select placeholder="Select Country">
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Form.Select>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label>State</Form.Label>
                                            <Form.Select placeholder="Select Country">
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Form.Select>
                                        </Col>
                                        <h6>Other Details </h6>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>EXO Policy Number</Form.Label>
                                                <Form.Control type="email" placeholder="Enter EXO Policy Number" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>EXO Policy Expiry Date</Form.Label>
                                                <Form.Control type="email" placeholder="Enter EXO Policy Expiry Date" />
                                            </Form.Group>
                                        </Col>
                                        <div className="form-area-bottom">
                                        <Form.Label>Are you Licensed?</Form.Label>
                                        <Form>
                                            {['checkbox'].map((type) => (
                                                <div key={`default-${type}`} className="mb-3">
                                                    <Form.Check // prettier-ignore
                                                        type={type}
                                                        id={`default-${type}`}
                                                        label={`Yes`}
                                                    />

                                                    <Form.Check
                                                        type={type}
                                                        label={`No`}
                                                        id={`No`}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                        </div>
                                        <div className="uplaod-document-btn">
                                            <Button type="button" variant="unset"><a href="/pick-theme">Upload Document</a></Button>
                                        </div>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
};

export default SignUp;