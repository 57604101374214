import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const history = useNavigate();

  function changePath(path) {
    history(path);
  }

  function activePath(path) {
    if (location.pathname === path) {
      return "active";
    }
  }

  return (
    <>
    <section className="sidebar">
        <div className="sidebar-content">
          <ul>
            <li className={activePath("/pick-theme")} onClick={() => changePath("/pick-theme")}><img src={require("../assets/images/home_icon.png")} /></li>
            <li className={activePath("/insurance-type")} onClick={() => changePath("/insurance-type")}><img src={require("../assets/images/add_icon.png")} /></li>
            <li className={activePath("/dashboard")} onClick={() => changePath("/dashboard")}><img className="dasdboard" src={require("../assets/images/dashboard_white.png")} /></li>
            <li className={activePath("/policy-management")} onClick={() => changePath("/policy-management")}><img src={require("../assets/images/policy_white.png")} /></li>
            <li className={activePath("/client-management")} onClick={() => changePath("/client-management")}><img src={require("../assets/images/client_white.png")} /></li>
            <li className={activePath("/partners")} onClick={() => changePath("/partners")}><img src={require("../assets/images/user&partner_icon.png")} /></li>
            <li className={activePath("")} onClick={() => changePath("/")}><img className="logout" src={require("../assets/images/logout_icon.png")} /></li>
          </ul>
        </div>
      </section>
    </>
  )
};
export default Sidebar;