import { Button, Col, Container, Form, Row } from "react-bootstrap"

const MainSignup = () => {
    return (
        <>
            <section className="login-section">
                <div className="login-top-logo">
                <a href="https://insureo.io/" target="_blank"><img src={require("../assets/images/logo.png")} /></a>
                </div>
                <Container>
                    <Row className="justify-content-center mt-5">
                        <Col md={10}>
                            <div className="login-head">
                                <h1>Sign Up</h1>
                                <p>This email isn't associated with any account. <br /> Double Check your email or use it to sign up and create an account.</p>
                                <Row className="justify-content-center">
                                    <Col md={6}>
                                        <div className="complete-profile">
                                            <div class="w3-light-grey">
                                                <div class="w3-container w3-blue" style={{ width: "75%" }}>75%</div>
                                            </div>
                                            <a href="/sign-up">Complete Your Profile</a>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <div className="login-form">
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email" />
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label>Confirm Email</Form.Label>
                                            <Form.Control type="email" placeholder="Enter Confirm Email" />
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" placeholder="Enter Password" />
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control type="password" placeholder="Enter Confirm Password" />
                                        </Form.Group>
                                        <div className="continue-btn">
                                            <Button variant="unset"><a href="/">Sign Up<i class="fa-solid fa-arrow-right-long"></i></a></Button>
                                        </div>
                                        <p>Or</p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="login-btns-right">
                                        <ul>
                                            <li className="google-btn"><img src={require("../assets/images/logo_google.svg").default} /><span>Continue with Google</span></li>
                                            <li className="facebook-btn"><img src={require("../assets/images/facebook_logo.svg").default} /><span>Continue with Facebook</span></li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <div className="login-footer">
                            <p>By Signing up, you are agree to our <span>Term's of Use</span> and acknowledge you've read our <span>Privacy Policy</span></p>
                            <p>This site is protected by reCAPTCHA Enterprise. <span>Google's Privacy Policy</span> and <span>Terms of Service</span> apply</p>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default MainSignup;