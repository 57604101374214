import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { BarGraph } from "../common/BarGraph";

const Dashboard = () => {
    return (
        <>
            <section className="common-spacing">
                <Container fluid>
                    <div className="policy-management-area">
                        <div className="policy-management-top">
                            <h5>Dashboard</h5>
                        </div>
                        <div className="dashboard-content">
                            <h4 className="tody-text">Today’s Sales</h4>
                            <h6 className="sales-text">Sales Summary</h6>
                            <Row>
                                <Col md={3}>
                                    <div className="dashboard-box">
                                        <div className="dashboard-box-left">
                                            <h6>Clients</h6>
                                            <h2>12</h2>
                                        </div>
                                        <div className="dashboard-box-right">
                                            <img src={require("../assets/images/client_icon.png")} />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="dashboard-box second">
                                        <div className="dashboard-box-left">
                                            <h6>Policies</h6>
                                            <h2>10</h2>
                                        </div>
                                        <div className="dashboard-box-right">
                                            <img src={require("../assets/images/policy_icon.png")} />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="dashboard-box third">
                                        <div className="dashboard-box-left">
                                            <h6>Policies</h6>
                                            <h2>10</h2>
                                        </div>
                                        <div className="dashboard-box-right">
                                            <img src={require("../assets/images/policy_icon.png")} />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="dashboard-box four">
                                        <div className="dashboard-box-left">
                                            <h6>Policies</h6>
                                            <h2>10</h2>
                                        </div>
                                        <div className="dashboard-box-right">
                                            <img src={require("../assets/images/policy_icon.png")} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="dashobard-chart-main">
                            <Row>
                                <Col md={6}>
                                    <div className="graph-section">
                                        <h4 className="tody-text">Today’s Sales</h4>
                                        <h6 className="sales-text">Sales Summary</h6>
                                        <BarGraph graphData={[{ month: 'Jan', value: 10 }, { month: 'Feb', value: 20 }, { month: 'Mar', value: 30 }, { month: 'Apr', value: 40 }, { month: 'May', value: 50 }, { month: 'Jun', value: 60 }, { month: 'Jul', value: 70 }, { month: 'Aug', value: 80 }, { month: 'Sep', value: 90 }, { month: 'Oct', value: 100 }, { month: 'Nov', value: 110 }, { month: 'Dec', value: 120 }, { month: 'Nov', value: 110 }, { month: 'Nov', value: 110 }, { month: 'Nov', value: 110 }, { month: 'Nov', value: 110 }, { month: 'Nov', value: 110 }, { month: 'Nov', value: 110 }, { month: 'Nov', value: 110 },]} />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="graph-section">
                                        <h4 className="tody-text">Today’s Sales</h4>
                                        <h6 className="sales-text">Sales Summary</h6>
                                        <table className="table-main table table-responsive">
                                            <thead>
                                                <tr>
                                                    <th>Task</th>
                                                    <th>Progress</th>
                                                    <th className="text-right">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p className="d-flex">
                                                            <Form.Check aria-label="option 1" className="me-2" /> Analytics
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div class="progress">
                                                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="70"
                                                                aria-valuemin="0" aria-valuemax="100" style={{ width: '70%' }}>
                                                                <span class="sr-only">70% Complete</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="in-progress">In Progress</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="d-flex">
                                                            <Form.Check aria-label="option 1" className="me-2" /> Analytics
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div class="progress">
                                                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="70"
                                                                aria-valuemin="0" aria-valuemax="100" style={{ width: '70%' }}>
                                                                <span class="sr-only">70% Complete</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="in-progress red">Cancelled</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="d-flex">
                                                            <Form.Check aria-label="option 1" className="me-2" /> Analytics
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div class="progress">
                                                            <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="70"
                                                                aria-valuemin="0" aria-valuemax="100" style={{ width: '20%' }}>
                                                                <span class="sr-only">70% Complete</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="in-progress green">Completed</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="d-flex">
                                                            <Form.Check aria-label="option 1" className="me-2" /> Analytics
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div class="progress">
                                                            <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="70"
                                                                aria-valuemin="0" aria-valuemax="100" style={{ width: '80%' }}>
                                                                <span class="sr-only">70% Complete</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="in-progress">In Progress</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="d-flex">
                                                            <Form.Check aria-label="option 1" className="me-2" /> Analytics
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div class="progress">
                                                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="70"
                                                                aria-valuemin="0" aria-valuemax="100" style={{ width: '70%' }}>
                                                                <span class="sr-only">70% Complete</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="in-progress">In Progress</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="d-flex">
                                                            <Form.Check aria-label="option 1" className="me-2" /> Analytics
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div class="progress">
                                                            <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="70"
                                                                aria-valuemin="0" aria-valuemax="100" style={{ width: '7%' }}>
                                                                <span class="sr-only">70% Complete</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="in-progress">In Progress</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="d-flex">
                                                            <Form.Check aria-label="option 1" className="me-2" /> Analytics
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div class="progress">
                                                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="70"
                                                                aria-valuemin="0" aria-valuemax="100" style={{ width: '70%' }}>
                                                                <span class="sr-only">70% Complete</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="in-progress">In Progress</span>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                </Container>
            </section>
        </>
    )
};
export default Dashboard;