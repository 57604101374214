import { Col, Container, Form, Row, Button } from "react-bootstrap";

const PickTheme = () => {
    return (
        <>
            <section className="common-spacing pick-theme-area">
                <Container fluid>

                    <Row className="justify-content-center">
                        <Col md={12} className="p-0">
                            <div className="pick-theme-select-area">
                                <div className="desktop-mobile-icon">
                                    <img className="desktop-icon" src={require("../assets/images/monitor.png")} />
                                    <img className="mobile-icon" src={require("../assets/images/smartphone.png")} />
                                </div>
                                <img className="your-logo" src={require("../assets/images/your-logo.png")} />
                                <div className="pick-theme-select-area-right">
                                    <div className="select-color-araea">
                                        <Form.Label>Select Theme</Form.Label>
                                        <a href="#">
                                            <ul class="color-list">
                                                <li class="color-swatch current-color">
                                                    <a href="#"><span style={{ background: '#088183' }}></span></a>
                                                </li>
                                                <li class="color-swatch">
                                                    <a href="#"><span style={{ background: "#002b78" }}></span></a>
                                                </li>
                                                <li class="color-swatch">
                                                    <a href="#"><span style={{ background: "#0e9bc9" }}></span></a>
                                                </li>
                                            </ul>
                                        </a>
                                    </div>
                                    <Button type="button" variant="unset">Save</Button>
                                </div>
                            </div>
                        </Col>
                      

                        <Col md={10} lg={10} xl={10} xxl={10} className="p-0 position-relative">
                            <div className="under-pick-theme">
                                <div className="pick-theme-content" data-aos="fade-left" data-aos-duration="1000">

                                    <div className="pick-theme-content-inner">

                                        <div className="pick-theme-logo-area">
                                            <div className="user-info-top">
                                                <div className="name-top">
                                                <h5>Adam </h5>
                                                <h6>Smith</h6>
                                                </div>
                                              
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </div>

                                            <div className="phone-area-right">
                                                <h6 class="phone-number">
                                                <p><i class="fa-solid fa-phone"></i> +1 951-842-5521</p><i class="fa-solid fa-pen-to-square"></i></h6>
                                                <a>adam@gmail.com <i class="fa-solid fa-pen-to-square"></i></a>
                                            </div>

                                        </div>
                                        <Row className="justify-content-center">
                                            <Col md={10} xl={10} xxl={9}>
                                                <div className="banner-head-area">
                                                    <h2>All Travel Insurance<br /> Policies on <span>ONE</span><br /> Platform</h2>
                                                    <div className="banner-search-area">
                                                        <p>Search, <span>Compare,</span> & Insure!</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        {/* <div className="pick-theme-top-area">
                                        <div className="pick-theme-top-left">
                                            <p><span>First Name:</span>Adam</p>
                                            <p><span>Last Name:</span>Smith</p>
                                        </div>
                                        <div className="pick-theme-top-right">
                                            <p><span>Phone Number:</span>+1234556783</p>
                                            <p><span>Email:</span>adam@gmail.com</p>
                                        </div>
                                    </div> */}
                                        <div className="insurance-content-mid">
                                            <Row className="justify-content-center">
                                                <Col md={3} className="mb-2">
                                                    <div className="isurance-area">
                                                        <img src={require("../assets/images/visitor.svg").default} />
                                                        <h6>Visitor</h6>
                                                    </div>
                                                </Col>
                                                <Col md={3} className="mb-4">
                                                    <div className="isurance-area">
                                                        <img src={require("../assets/images/super-visa.svg").default} />
                                                        <h6>Super Visa</h6>
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row className="justify-content-center">
                                                <Col md={3} className="mb-4">
                                                    <div className="isurance-area">
                                                        <img src={require("../assets/images/student.svg").default} />
                                                        <h6>Student</h6>
                                                    </div>
                                                </Col>
                                                <Col md={3} className="mb-4">
                                                    <div className="isurance-area">
                                                        <img src={require("../assets/images/trip.svg").default} />
                                                        <h6>Trip</h6>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>


                                        <Row className="justify-content-center">
                                            <Col md={8}>
                                                <div className="overlay-banner">
                                                    <div className="overlay-banner-top">
                                                        <h5>Login & Manage your travel policies on the Go.</h5>
                                                        <h4>#TavelInsuranceMadeEasy </h4>
                                                    </div>
                                                    <div className="banner-mid-outer">
                                                        <div className="banner-content-left">
                                                            <h6>Enjoy seamless and convenient way of getting travel policy as per your requirements.
                                                            </h6>
                                                            <ul>
                                                                <li><img src={require("../assets/images/quick_quote_icon.png")} />Quick Quote</li>
                                                                <li><img src={require("../assets/images/choose_fromvarious_plans_icons.png")} />Choose From Various Plans </li>
                                                                <li><img src={require("../assets/images/buy_quickonthe_go_icon.png")} />Buy Quick on the Go</li>
                                                                <li><img src={require("../assets/images/manage_updateyoupolicy_icon.png")} />Manage update you policy. </li>
                                                            </ul>
                                                            <h4>LETS MAKE LIFE EASY </h4>
                                                            <p>Share QR code with your Friends and Family and offer them hassle free quick travel insurance</p>
                                                        </div>
                                                        <div className="banner-content-right">
                                                            <img src={require("../assets/images/scanner_image.png")} ></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </>
    )
};
export default PickTheme;