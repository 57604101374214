import React, { Col, Container, Form, Row, Button } from "react-bootstrap";
const InsuranceType = () => {
    return (
        <>
            <section className="common-spacing signup-area">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col md={12}>
                            <h2>Travel Insurance</h2>
                            <div className="signup-area-content travel-insurance-top-area   " data-aos="fade-up" data-aos-duration="1000">
                                <Row>
                                    <Col md={2} className="mb-4">
                                        <div className="inurance-area-content">
                                            <img src={require("../assets/images/logo1.jpg")} />
                                            <Form.Check />
                                        </div>
                                    </Col>
                                    <Col md={2} className="mb-4">
                                        <div className="inurance-area-content">
                                            <img src={require("../assets/images/logo2.jpg")} />
                                            <Form.Check />
                                        </div>
                                    </Col>
                                    <Col md={2} className="mb-4">
                                        <div className="inurance-area-content">
                                            <img src={require("../assets/images/logo3.jpg")} />
                                            <Form.Check />
                                        </div>
                                    </Col>
                                    <Col md={2} className="mb-4">
                                        <div className="inurance-area-content">
                                            <img src={require("../assets/images/logo4.jpg")} />
                                            <Form.Check />
                                        </div>
                                    </Col>
                                    <Col md={2} className="mb-4">
                                        <div className="inurance-area-content">
                                            <img src={require("../assets/images/logo5.jpg")} />
                                            <Form.Check />
                                        </div>
                                    </Col>
                                    <Col md={2} className="mb-4">
                                        <div className="inurance-area-content">
                                            <img src={require("../assets/images/logo6.jpg")} />
                                            <Form.Check />
                                        </div>
                                    </Col>
                                    <Col md={2} className="mb-4">
                                        <div className="inurance-area-content">
                                            <img src={require("../assets/images/logo7.jpg")} />
                                            <Form.Check />
                                        </div>
                                    </Col>
                                    <Col md={2} className="mb-4">
                                        <div className="inurance-area-content">
                                            <img src={require("../assets/images/logo8.jpg")} />
                                            <Form.Check />
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className="inurance-area-content">
                                            <img src={require("../assets/images/logo9.jpg")} />
                                            <Form.Check />
                                        </div>
                                    </Col>
                                    <Col md={2} >
                                        <div className="inurance-area-content">
                                            <img src={require("../assets/images/logo10.png")} />
                                            <Form.Check />
                                        </div>
                                    </Col>
                                    <Col md={2} >
                                        <div className="inurance-area-content">
                                            <img src={require("../assets/images/logo11.png")} />
                                            <Form.Check />
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className="inurance-area-content">
                                            <img src={require("../assets/images/logo12.png")} />
                                            <Form.Check />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="travel-insurance-right">
                                    <h5>Link</h5>
                                    <Form>
                                        <Row>
                                            <Col md={2}>
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Destination Canada</Form.Label>
                                                    <Form.Control type="text" placeholder="https://www.tugo.com/" readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Manulife</Form.Label>
                                                    <Form.Control type="text" placeholder="https://www.tugo.com/" readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Tugo</Form.Label>
                                                    <Form.Control type="text" placeholder="https://www.tugo.com/" readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Allianz</Form.Label>
                                                    <Form.Control type="text" placeholder="https://www.tugo.com/" readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Ingle</Form.Label>
                                                    <Form.Control type="text" placeholder="https://www.tugo.com/" readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Travel Shield</Form.Label>
                                                    <Form.Control type="text" placeholder="https://www.tugo.com/" readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>21st Century</Form.Label>
                                                    <Form.Control type="text" placeholder="https://www.tugo.com/" readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Travelance</Form.Label>
                                                    <Form.Control type="text" placeholder="https://www.tugo.com/" readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>GMS</Form.Label>
                                                    <Form.Control type="text" placeholder="https://www.tugo.com/" readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Discover Canada</Form.Label>
                                                    <Form.Control type="text" placeholder="https://www.tugo.com/" readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>JF Royal Plan</Form.Label>
                                                    <Form.Control type="text" placeholder="https://www.tugo.com/" readOnly />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Secure Travel</Form.Label>
                                                    <Form.Control type="text" placeholder="https://www.tugo.com/" readOnly />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Button type="button" variant="unset"><a href="#">Save</a></Button>
                                    </Form>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}
export default InsuranceType