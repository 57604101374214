import './App.css';
import SignUp from './components/SignUp';
import { Routes, Route, useLocation } from "react-router-dom";
import "./../src/assets/css/style.css";
import PickTheme from './components/PickTheme';
import InsuranceType from './components/InsuranceType';
import Sidebar from './common/Sidebar';
import Login from './common/Login';
import Dashboard from './components/Dashboard';
import PolicyManagement from './components/PolicyManagement';
import Header from './common/Header';
import ClientManagement from './components/ClientManagement';
import Setting from './components/Setting';
import PartnersUsers from './components/PartnersUsers';
import GetStarted from './components/GetStarted';
import MainSignup from './common/MainSignup';
import Footer from './common/Footer';
function App() {

  const location = useLocation();
  const NON_SIDEBAR = ["/", "/get-started", "/login", "/main-signup", "/sign-up"];
  const NON_HEADER = ["/", "/get-started", "/login", "/main-signup", "/sign-up"];
  const NON_FOOTER = ["/", "/get-started", "/login", "/main-signup", "/sign-up"];

  return (
    <>
      {!NON_SIDEBAR.includes(location.pathname) && <Sidebar />}
      {!NON_HEADER.includes(location.pathname) && <Header />}
      
      <Routes>
        <Route
          path="/sign-up"
          element={<SignUp />}>
        </Route>
        <Route
          path="/"
          element={<Login />}>
        </Route>
        <Route
          path="/pick-theme"
          element={<PickTheme />}>
        </Route>
        <Route
          path="/insurance-type"
          element={<InsuranceType />}>
        </Route>
        <Route
          path="/dashboard"
          element={<Dashboard />}>
        </Route>
        <Route
          path="/policy-management"
          element={<PolicyManagement />}>
        </Route>
        <Route
          path="/client-management"
          element={<ClientManagement />}>
        </Route>
        <Route
          path="/settings"
          element={<Setting />}>
        </Route>
        <Route
          path="/partners"
          element={<PartnersUsers />}>
        </Route>
        <Route
          path="/get-started"
          element={<GetStarted />}>
        </Route>
        <Route
          path="/main-signup"
          element={<MainSignup />}>
        </Route>
      </Routes>
      {!NON_FOOTER.includes(location.pathname) && <Footer />}
    </>
  );
}

export default App;
