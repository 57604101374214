import { useState } from "react";
import { Button, Container, Form, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const PartnersUsers = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <section className="common-spacing">
                <Container fluid>
                    <div className="policy-management-area">
                        <div className="policy-management-top">
                            <h5>Partners & Users Management</h5>
                            <Button type="button" variant="unset" onClick={handleShow}>Add Client</Button>
                        </div>
                        <div className="policy-management-content">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone Number</th>
                                        <th>Policies</th>
                                        <th>Permission</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>
                                            <Link to="/settings" className="setting-txt">
                                            <span>Setting <i className="fa-solid fa-gear"></i></span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>
                                            <Link to="/settings" className="setting-txt">
                                            <span>Setting <i className="fa-solid fa-gear"></i></span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>
                                            <Link to="/settings" className="setting-txt">
                                            <span>Setting <i className="fa-solid fa-gear"></i></span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>
                                            <Link to="/settings" className="setting-txt">
                                            <span>Setting <i className="fa-solid fa-gear"></i></span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>
                                            <Link to="/settings" className="setting-txt">
                                            <span>Setting <i className="fa-solid fa-gear"></i></span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>
                                            <Link to="/settings" className="setting-txt">
                                            <span>Setting <i className="fa-solid fa-gear"></i></span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>
                                            <Link to="/settings" className="setting-txt">
                                            <span>Setting <i className="fa-solid fa-gear"></i></span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>
                                            <Link to="/settings" className="setting-txt">
                                            <span>Setting <i className="fa-solid fa-gear"></i></span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>
                                            <Link to="/settings" className="setting-txt">
                                            <span>Setting <i className="fa-solid fa-gear"></i></span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>
                                            <Link to="/settings" className="setting-txt">
                                            <span>Setting <i className="fa-solid fa-gear"></i></span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>
                                            <Link to="/settings" className="setting-txt">
                                            <span>Setting <i className="fa-solid fa-gear"></i></span>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>
                                            <Link to="/settings" className="setting-txt">
                                            <span>Setting <i className="fa-solid fa-gear"></i></span>
                                            </Link>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Container>
            </section>

            {/* modal */}
            <Modal show={show} onHide={handleClose} centered className="add-policy">
                <Modal.Header closeButton>
                    <Modal.Title>Add Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" placeholder="Enter Email" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter Phone Number" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" rows={5} placeholder="Enter Notes" />
                        </Form.Group>
                        <div className="policy-document">
                            <Form.Label>Upload Files</Form.Label>
                            <div className="documnet-upload-area">
                                <i class="fa-solid fa-plus"></i>
                                <Form.Control type="file" />
                            </div>
                            <p>Limit 2MB</p>
                        </div>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default PartnersUsers;