import { Container, Dropdown, Button } from "react-bootstrap";

const Header = () => {
    return (
        <>
            <section className="header">
                <Container fluid>
                    <div className="header-top">
                        <div className="header-left">
                            <div className="header-logo-top">
                            <a href="https://insureo.io/" target="_blank"><img src={require("../assets/images/logo.png")} /></a>
                            </div>
                            {/* <div className="desktop-mobile-icon">
                                <img className="desktop-icon" src={require("../assets/images/monitor.png")} />
                                <img className="mobile-icon" src={require("../assets/images/smartphone.png")} />
                            </div> */}
                            <div className="url-coonect-domain">
                                <p>https://www.mydomain.com <a>Connect Your Domain</a></p>
                            </div>
                        </div>
                        <div className="header-right">
                            <div className="header-top-button">
                                <p>Save</p>
                                <h6>Preview</h6>
                                <Button type="Button" variant="unset">Publish</Button>
                            </div>
                        </div>
                    </div>

                </Container>
            </section>
        </>
    );
};
export default Header;