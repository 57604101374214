import { BarChart } from "@mui/x-charts"

export const BarGraph = ({ graphData }) => {

    // const customToolTip = ({ itemData, series }) => {
    //     if (itemData.dataIndex === undefined) return null
    //     return (
    //         <ParentCustomToolTip backgroundColor={series.label ? series?.color : colors.length > 1 ? colors[itemData.dataIndex] : colors[0]} label={series.label ? `${series.label.toUpperCase()} (${graphData[itemData.dataIndex].month})` : graphData[itemData.dataIndex].month} value={series.data[itemData.dataIndex]} prefix={prefix} suffix={suffix}  />
    //     )
    // }
    
    return (
        <BarChart
            tooltip={{
                trigger: "item",
            }}
            slotProps={{
                legend: {
                    hidden: true,
                }
            }}
            axisHighlight={{
                x: "none",
            }}
            grid={{
                horizontal: true,
            }}
            xAxis={[{
                scaleType: 'band', dataKey: 'month'
                }]}
            // yAxis={[{ valueFormatter: (value) => {
            //         if (Math.floor(value) === value) {
            //             return (prefix && suffix) ? `${prefix}${value}${suffix}` : prefix ? `${prefix}${value}` : suffix ? `${value}${suffix}` : `${value}` 
            //         }
            //         return "";
            //     }
            // }]}
            dataset={graphData}
            series={[{ dataKey: 'value'}]}
            borderRadius={10}
            height={400}
            sx={{
                padding: "8px",
                "& .MuiChartsAxis-bottom .MuiChartsAxis-line":{
                    stroke:"#E5E5EF",
                },
                "& .MuiChartsAxis-left .MuiChartsAxis-line":{
                    stroke:"#fff",
                },
                "& .MuiChartsAxis-tick": {
                    stroke: "#fff !important",
                },
                "& text": {
                    fill: "#000 !important",
                },
                "& .MuiBarElement-root": {
                    strokeWidth: 2,
                    borderRadius: "10px 10px 0 0",
                },
            }}
        />
    )
}