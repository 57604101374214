import { useState } from "react";
import { Button, Container, Form, Modal, Table } from "react-bootstrap";

const PolicyManagement = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <section className="common-spacing">
                <Container fluid>
                    <div className="policy-management-area">
                        <div className="policy-management-top">
                            <h5>Policy Management</h5>
                            <Button type="button" variant="unset" onClick={handleShow}>Add Policy</Button>
                        </div>
                        <div className="policy-management-content">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Policy Name</th>
                                        <th>Customer</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Policy Documnent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>11</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td>12</td>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>07-10-2024</td>
                                        <td>15-10-2024</td>
                                        <td>3</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Container>
            </section>
            {/* modal */}
            <Modal show={show} onHide={handleClose} centered className="add-policy">
                <Modal.Header closeButton>
                    <Modal.Title>Add Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Policy Name</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                        </Form.Group>
                        <Form.Label>Customer</Form.Label>
                        <Form.Select aria-label="Default select example" className="mb-3">
                            <option>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control type="date" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Expiry Date</Form.Label>
                            <Form.Control type="date" />
                        </Form.Group>
                        <div className="policy-document">
                            <Form.Label>Policy Document</Form.Label>
                            <div className="documnet-upload-area">
                                <i class="fa-solid fa-plus"></i>
                                <Form.Control type="file" />
                            </div>
                        </div>
                       
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};
export default PolicyManagement;