import { Button, Col, Container, Form, Row } from "react-bootstrap"

const Login = () => {
    
    return (
        <>
            <section className="login-section">
                <div className="login-top-logo">
                <a href="https://insureo.io/" target="_blank"><img src={require("../assets/images/logo.png")} /></a>
                </div>
                <Container>
                    <Row className="justify-content-center mt-5">
                        <Col md={10}>
                            <div className="login-head">
                                <h1>Log In</h1>
                                <p>Don't have an account? <a href="/main-signup">Sign Up</a></p>
                            </div>
                            <Row className="align-items-center login-mid-area">
                                <Col md={6}>
                                    <div className="login-form">
                                        <Form.Group className="mb-2" controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Enter Email" />
                                        </Form.Group>
                                        <a>Forgot Email?</a>
                                        <div className="continue-btn">
                                            <Button variant="unset"><a href="/get-started">Continue with Email <i class="fa-solid fa-arrow-right-long"></i></a></Button>
                                        </div>
                                        <p>Or</p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                     <div className="login-btns-right">
                                            <ul>
                                                <li className="google-btn"><img src={require("../assets/images/logo_google.svg").default} /><span>Continue with Google</span></li>
                                                <li className="facebook-btn"><img src={require("../assets/images/facebook_logo.svg").default} /><span>Continue with Facebook</span></li>
                                                <li className="apple-btn"><img src={require("../assets/images/apple_icon.svg").default} /><span>Continue with Apple</span></li>
                                            </ul>
                                     </div>
                                </Col>
                            </Row>
                        </Col>
                        <div className="login-footer">
                          <ul>
                            <li>Terms of Use</li>
                            <li>Privacy Policy</li>
                          </ul>
                          <p>This site is protected by reCAPTCHA Enterprise. <span>Google's Privacy Policy</span> and <span>Terms of Service</span> apply</p>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default Login;